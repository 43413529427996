<template>
  <pro-menu-layout>
    <pro-tender-form-render
      :mode="$route.params.mode"
      :code="$route.params.code"
      :tenderId="$route.params.id"
      :formDataPromise="mergedFormDataPromise"
      @pro-form-submit="$listeners['pro-form-submit']"
    />
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";
import PROSmartFormMixin from "@/views/mixins/ProSmartForm";

export default {
  name: "DocumentViewForm",
  components: { ProMenuLayout, ProTenderFormRender },
  mixins: [PROSmartFormMixin],
  props: {
    injectFormData: Object,
    documentStatus: Number,
  },
  data() {
    return {
      formDataPromise: this.loadFormData(
        this.$route.params.mode,
        this.$route.name,
        this.$route.params.code,
        this.$route.params.id
      ),
      mergedFormDataPromise: null,
    };
  },
  methods: {
    mergeFormData() {
      this.mergedFormDataPromise = this.formDataPromise.then((formData) =>
        Object.assign(
          formData,
          { documentStatus: this.documentStatus },
          this.injectFormData
        )
      );
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.formDataPromise = this.loadFormData(
      to.params.mode,
      to.name,
      to.params.code,
      to.params.id
    );
    this.mergeFormData();
    next();
  },
  created() {
    this.$watch("injectFormData", this.mergeFormData, {
      immediate: true,
      deep: true,
    });
  },
};
</script>
