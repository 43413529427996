export default {
  methods: {
    loadFormData(mode, routeName, code, tenderId) {
      switch (mode) {
        case "Edit": {
          return this.$proSmart.tender.getWorkflowDefaultResult(
            this,
            tenderId,
            code
          );
        }
        case "View": {
          return this.$proSmart.codeTable
            .getRouteWorkflowCode(this, routeName, code)
            .then((workflowList) =>
              Promise.all(
                workflowList.map((workflow) =>
                  this.$proSmart.tender.getTenderWorkflowInfo(
                    this,
                    tenderId,
                    workflow.workflowCode
                  )
                )
              )
            )
            .then((formDataList) => Object.assign({}, ...formDataList));
        }
        default:
          return Promise.reject("Invalid mode");
      }
    },
  },
};
